import axios from "axios";

let instance = axios.create({
  withCredentials: true,
});

instance.interceptors.request.use(request => {
  request.headers['Accept'] = 'application/json';
  request.headers['Content-Type'] = 'application/json';
  request.headers['ngrok-skip-browser-warning'] = '69420';

  return request;
});

instance.interceptors.response.use(
  response => { return response; },
  async error => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
  }
);

export default instance;
