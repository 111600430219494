<template>
  <ShopifyItemMobileStory
    v-if="[
      'ADD_TO_CART',
      'DIRECT_TO_CART',
      'CHECKOUT',
    ].includes(scene.action.type)"
    @sceneClick="onSceneClick"
    @video-play="onVideoPlay"
    @video-pause="onVideoPause"
    @mute="onMute"
    @unmute="onUnmute"
    @close="onClose"
    @goToPrevScene="onPrevSceneClick"
    @goToNextScene="onNextSceneClick"
    @goToCart="onGoToCart"
    @closeCart="onCartClose"
    :is-playing="isPlaying"
    :is-muted="isMuted"
    :shopify-items="shopifyItems"
    :journey="journey"
    :scene="scene"
    :show-watermark="showWatermark"
    :show-mobile-scene-controls="showMobileSceneControls"
    :is-cart-open="isCartOpen"
    :is-window-mode="isWindowMode"
  />
    <SimpleButtonMobileStory
      v-else
      @sceneClick="onSceneClick"
      @video-play="onVideoPlay"
      @video-pause="onVideoPause"
      @mute="onMute"
      @unmute="onUnmute"
      @close="onClose"
      @goToPrevScene="onPrevSceneClick"
      @goToNextScene="onNextSceneClick"
      @goToCart="onGoToCart"
      @closeCart="onCartClose"
      :is-playing="isPlaying"
      :is-muted="isMuted"
      :journey="journey"
      :scene="scene"
      :show-watermark="showWatermark"
      :show-mobile-scene-controls="showMobileSceneControls"
      :is-cart-open="isCartOpen"
    />
</template>

<script>
import ShopifyItemMobileStory from './Shopify/mobile/ShopifyItemMobileStory.vue';
import SimpleButtonMobileStory from './Simple/mobile/SimpleButtonMobileStory.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import ShopifyItemStory from '@/components/stories/Shopify/desktop/ShopifyItemStory.vue';

export default {
  name: 'MobileStory',
  components: {ShopifyItemStory, VideoPlayer, ShopifyItemMobileStory, SimpleButtonMobileStory },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    shopifyItems: {
      type: Object | Array,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    showWatermark: Boolean,
    showMobileSceneControls: {
      type: Boolean,
      default: false
    },
    isCartOpen: {
      type: Boolean,
      default: false
    },
    isWindowMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSceneClick(scene) {
      this.$emit('scene-click', scene);
    },
    onVideoPlay(sceneId) {
      this.$emit('video-play', sceneId);
    },
    onVideoPause(sceneId) {
      this.$emit('video-pause', sceneId);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onClose() {
      this.$emit('close');
    },
    onPrevSceneClick() {
      this.$emit('goToPrevScene');
    },
    onNextSceneClick() {
      this.$emit('goToNextScene');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    onCartClose() {
      this.$emit('closeCart');
    }
  }
}
</script>
