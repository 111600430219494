export const updateCartBubbleForStore = (storeDomain, cartItemsCount) => {
  if (storeDomain.includes('mathscientific.lt')) {
    return updateCartBubbleForMathScientific(cartItemsCount);
  }

  if (storeDomain.includes('www.gentleday.lt')) {
    return updateCartBubbleForGentleDay(cartItemsCount);
  }

  if (storeDomain.includes('myequa.lt')) {
    return updateCartBubbleForMyEqua();
  }
}

// @TODO: fix
const updateCartBubbleForMathScientific = cartItemsCount => {
  const cartCountWrapper = document.querySelector('.header__cart-count');
  cartCountWrapper.innerHTML = `<cart-count class="count-bubble">${cartItemsCount}</cart-count>`;
};

const updateCartBubbleForGentleDay = cartItemsCount => {
  const cartBubble = document.createElement("div");
  cartBubble.className = "cart-count-bubble";
  cartBubble.innerHTML = `<span aria-hidden="true">${cartItemsCount}</span><span class="visually-hidden">${cartItemsCount} prekės(-ių)</span>`;

  const cartLink = document.getElementById("cart-icon-bubble");
  cartLink.appendChild(cartBubble);
};

const updateCartBubbleForMyEqua = () => {
  document.documentElement.dispatchEvent(new CustomEvent('ajaxProduct:added', {
    bubbles: true,
  }));
};

export const createOptionsKey = (options) => {
  return Object.keys(options).sort().reduce((acc, key) => `${acc}${key}:${options[key]};`, '');
};

// gid://shopify/ProductVariant/47923051037015 -> 47923051037015
export const parseNumericValueFromVariantId = variant => {
  const parts = variant.split('/');

  return parts[parts.length - 1];
};