<template>
  <div :id="floatingSceneFormatComputedId" class="floating-format" :style="getPositionStyleByJourneyType()" v-if="isVisible">
    <div class="simple-video-player-wrapper">
      <SimpleVideoPlayer
        v-if="isAutoplayEnabled"
        class="simple-video-player"
        :style="{ width: `${widgetWidth}px`, height: `${widgetHeight}px` }"
        :scene="scene"
        :cloudName="cloudName"
        :publicId="scene.video.public_id"
        :rounded="true"
      />

      <div
        v-else
        class="thumbnail"
        :style="{ width: `${widgetWidth}px`, height: `${widgetHeight}px` }"
        v-html="getThumbnailComponent(scene)"
      >
      </div>

      <div class="scene-button" @click="handleActionButtonClick()" @touchstart="(e) => { e.preventDefault(); handleActionButtonClick(); }" :style="{ width: `${widgetWidth}px`, height: `${widgetHeight}px` }">
        <span class="invisible"></span>
      </div>
    </div>

    <div class="close-button-wrapper">
      <button class="close-button" @click="hideFloatingVideo">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isLocalhost } from '@/helpers/api';
import { generateRandomString } from '@/helpers/string';
import SimpleVideoPlayer from '@/components/video-players/SimpleVideoPlayer.vue';
import {getThumbnailSrcForScene, getVideoSrcForScene} from '@/helpers/video';

const defaultWidgetOffset = 40;
const defaultWidgetWidth = 160;
const defaultWidgetHeight = 284;

export default {
  name: 'FloatingFormat',
  components: {SimpleVideoPlayer},
  props: {
    journey: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      shadowRoot: 'util/shadowRoot',
      windowWidth: 'util/windowWidth',
    }),
    isMobileWidth() {
      return this.windowWidth < 426;
    },
    sceneWidth() {
      if (!this.hasJourneyConfig) {
        return null;
      }

      if (this.isMobileWidth) {
        return this.journey.config?.mobile_scene_width;
      }

      return this.journey.config.scene_width;
    },
    sceneSpacing() {
      if (!this.hasJourneyConfig) {
        return null;
      }

      if (this.isMobileWidth) {
        return this.journey.config?.mobile_scene_spacing;
      }

      return this.journey.config.scene_spacing;
    },
    floatingSceneFormatComputedId() {
      return `floating-scene-format-${this.floatingSceneFormatId}`;
    },
    scene() {
      return this.journey.scenes[0];
    },
    hasJourneyConfig() {
      return this.journey.config && Object.keys(this.journey.config).length > 0;
    },
    isAutoplayEnabled() {
      if (!this.hasJourneyConfig) {
        return false;
      }

      return this.journey.config.autoplay;
    },
    widgetWidth() {
      if (this.sceneWidth != null) {
        return this.sceneWidth;
      }

      return defaultWidgetWidth;
    },
    widgetHeight() {
      const aspectRatio = defaultWidgetWidth / defaultWidgetHeight;
      return this.widgetWidth / aspectRatio;
    },
    widgetSpacing() {
      if (this.sceneSpacing != null) {
        return this.sceneSpacing;
      }

      return defaultWidgetOffset;
    },
  },
  async created() {
    this.floatingSceneFormatId = generateRandomString();
    await this.appendFloatingFormatSceneToBody();
  },
  data() {
    return {
      isVisible: true,
      floatingSceneFormatId: null,
      floatingSceneFormatParentNode: null,
    }
  },
  methods: {
    getThumbnailComponent(scene) {
      const width = Math.round(this.widgetWidth);
      const height = Math.round(this.widgetHeight);

      const thumbnailSrc = getThumbnailSrcForScene(scene, `h_${height},w_${width}`);

      if (thumbnailSrc) {
        return `
          <div class="image-wrapper">
            <img src="${thumbnailSrc}" alt="Scene">
          </div>
        `;
      }

      const videoSrc = getVideoSrcForScene(scene);
      const style = `width: ${width}px; height: ${height}px; object-fit: cover;`;

      return `
        <video class="simple-video-player" muted style="${style}">
          <source src="${videoSrc}" />
        </video>
      `;
    },
    hideFloatingVideo() {
      this.isVisible = false;
    },
    async appendFloatingFormatSceneToBody() {
      const floatingSceneFormat = await this.getFloatingSceneFormat();

      this.floatingSceneFormatParentNode = floatingSceneFormat.parentNode;

      document.body.append(floatingSceneFormat);

      if (isLocalhost) {
        return;
      }

      const journeyUuid = this.journey.uuid;

      // need to also append styles to the head but only once
      if (!document.getElementById(`cloned-style-journey-${journeyUuid}`)) {
        const style = this.shadowRoot.getElementById(`style-journey-${journeyUuid}`);
        const clonedStyle = style.cloneNode(true);
        clonedStyle.setAttribute('id', `cloned-style-journey-${journeyUuid}`);
        document.head.append(clonedStyle);
      }
    },
    async getFloatingSceneFormat() {
      let hostNode = document;

      if (!isLocalhost) {
        hostNode = this.shadowRoot;
      }

      // eslint-disable-next-line
      while (true) {
        const floatingSceneFormat = hostNode.getElementById(this.floatingSceneFormatComputedId);

        if (floatingSceneFormat !== null) {
          return floatingSceneFormat;
        }

        await new Promise(resolve => setTimeout(resolve, 100));
      }
    },
    getVideoThumbnail(scene) {
      const thumbnail = scene.shopify_image_url || scene?.video.shopify_image_url || scene.thumbnail;

      if (thumbnail && thumbnail.length > 0) {
        return thumbnail;
      }

      const width = Math.round(this.widgetWidth);
      const height = Math.round(this.widgetHeight);

      return scene.video.thumbnail_url.replace('h_60,w_90', `h_${height},w_${width}`);
    },
    getPositionStyleByJourneyType() {
      const journeyType = this.journey.type;

      const sizeStyle = `width: ${this.widgetWidth}px; height: ${this.widgetHeight}px;`;
      const offsetStyle = this.getWidgetOffsetStyle(journeyType);

      return `${sizeStyle} ${offsetStyle}`;
    },
    getWidgetOffsetStyle(widgetPosition) {
      const offset = this.widgetSpacing;

      if (widgetPosition === 'FLOATING_TOP_LEFT') {
        return `z-index: 999999; position: fixed; top: ${offset}px; left: ${offset}px;`
      }

      if (widgetPosition === 'FLOATING_TOP_RIGHT') {
        return `z-index: 999999; position: fixed; top: ${offset}px; right: ${offset}px;`
      }

      if (widgetPosition === 'FLOATING_BOTTOM_LEFT') {
        return `z-index: 999999; position: fixed; bottom: ${offset}px; left: ${offset}px;`
      }

      if (widgetPosition === 'FLOATING_BOTTOM_RIGHT') {
        return `z-index: 999999; position: fixed; bottom: ${offset}px; right: ${offset}px;`
      }

      return '';
    },
    handleActionButtonClick() {
      this.$emit('click', {journey: this.journey, sceneId: this.scene.id, sceneUuid: this.scene.uuid});
    },
  }
}
</script>



