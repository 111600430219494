<template>
  <div ref="portalContent" :id="id">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Portal',
  props: {
    id: {
      type: String,
      required: true
    },
  },
  mounted() {
    this.moveToBody();
  },
  beforeDestroy() {
    this.removeFromBody();
  },
  methods: {
    moveToBody() {
      const el = this.$refs.portalContent;
      if (el) {
        const existingPortals = document.querySelectorAll('.portal');

        existingPortals.forEach(portal => {
          if (portal !== el) {
            portal.parentNode.removeChild(portal);
          }
        });

        el.classList.add('portal');
        document.body.insertBefore(el, document.body.firstChild);
      } else {
        console.error('Portal: Element not found to move to body.');
      }
    },
    removeFromBody() {
      const el = this.$refs.portalContent;

      try {
        if (el && el.parentNode) {
          el.parentNode.removeChild(el);
        }
      } catch (error) {
        console.error('Portal: Failed to remove element from body.', error);
      }
    }
  }
}
</script>