import storefrontApi from '@/api/storefront';
import {isLocalhost} from '@/helpers/api';

export default {
  getGraphQLEndpoint(storeDomain) {
    const apiVersion = '2024-01';
    return `https://${storeDomain}/api/${apiVersion}/graphql.json`;
  },
  getEmptyProductsResponse() {
    return {
      data: {
        data: {
          nodes: []
        }
      }
    };
  },
  getProductsByIds(storeDomain, storefrontAccessToken, productIds) {
    if (productIds.length === 0) {
      return this.getEmptyProductsResponse();
    }

    const graphQLEndpoint = this.getGraphQLEndpoint(storeDomain);

    let productNodes = '';
    productIds.forEach(productId => {
      productNodes += `"${productId}"` + ' ';
    });

    const query = `{
      nodes(ids: [${productNodes}]) {
        ... on Product {
          id
          title
          description
          availableForSale
          totalInventory
          handle
          images(first: 5) {
            nodes {
              altText
              src
            }
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          options {
            id
            name
            values
          }
          variants(first: 100) {
            nodes {
              id
              title
              availableForSale
              currentlyNotInStock
              quantityAvailable
              priceV2 {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }`;

    return storefrontApi.post(graphQLEndpoint, {query}, {
      headers: {
        'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
      }
    });
  },
  getCollectionsByIds(storeDomain, storefrontAccessToken, collectionIds) {
    if (collectionIds.length === 0) {
      return this.getEmptyProductsResponse();
    }

    const graphQLEndpoint = this.getGraphQLEndpoint(storeDomain);

    let collectionNodes = '';

    collectionIds.forEach(collectionId => {
      collectionNodes += `"${collectionId}"` + ' ';
    });

    const productsPerCollection = 10;

    const query = `{
      nodes(ids: [${collectionNodes}]) {
        ... on Collection {
          id
          title
          description
          updatedAt
          onlineStoreUrl
          trackingParameters
          image {
            src
            altText
          }
          products(first: ${productsPerCollection}) {
            nodes {
              id
              title
              description
              availableForSale
              totalInventory
              handle
              createdAt
              updatedAt
              isGiftCard
              onlineStoreUrl
              productType
              publishedAt
              requiresSellingPlan
              tags
              trackingParameters
              vendor
              images(first: 5) {
                nodes {
                  altText
                  src
                }
              }
              featuredImage {
                src
                altText
              }
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              options {
                id
                name
                values
              }
              variants(first: 100) {
                nodes {
                  id
                  title
                  availableForSale
                  currentlyNotInStock
                  quantityAvailable
                  priceV2 {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }`;

    return storefrontApi.post(graphQLEndpoint, {query}, {
      headers: {
        'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
      }
    });
  },
  async setCartAttributes(attributes) {
    if (isLocalhost) {
      return this.getAddToCartResponseForLocalhost();
    }

    const data = {attributes};
    const storeDomain = window.location.hostname;

    return storefrontApi.post(`https://${storeDomain}/cart/update.js`, data);

  },
  async addToCart(payload) {
    if (isLocalhost) {
      return this.getAddToCartResponseForLocalhost();
    }

    const data = {items: [payload]};
    const storeDomain = window.location.hostname;

    return storefrontApi.post(`https://${storeDomain}/cart/add.js`, data);
  },
  async updateCart(data) {
    if (isLocalhost) {
      console.log(`POST https://localhost/cart/change.js`, data)
      return;
    }

    const storeDomain = window.location.hostname;

    return storefrontApi.post(`https://${storeDomain}/cart/change.js`, data);
  },

  async getCart() {
    if (isLocalhost) {
      return this.getCartResponseForLocalhost();
    }

    const storeDomain = window.location.hostname;

    return storefrontApi.get(`https://${storeDomain}/cart.js`);
  },

  getAddToCartResponseForLocalhost() {
    return {
      data: {
        items: [
          {
            id: 41237596602467,
            properties: {},
            quantity: 2,
            variant_id: 41237596602467,
            key: "41237596602467:ade4252d-9f1d-42ef-a231-641168eb1d98",
            title: "The Complete Snowboard - Ice",
            price: 69995,
            original_price: 69995,
            discounted_price: 69995,
            line_price: 139990,
            original_line_price: 139990,
            total_discount: 0,
            discounts: [],
            sku: "",
            grams: 4536,
            vendor: "Snowboard Vendor",
            taxable: true,
            product_id: 7164424781923,
            product_has_only_default_variant: false,
            gift_card: false,
            final_price: 69995,
            final_line_price: 139990,
            presentment_price: 699.95,
            url: "/products/the-complete-snowboard?variant=41237596602467",
            featured_image: {
              alt: "Top and bottom view of a snowboard. The top view shows abstract circles and lines in shades of teal. The bottom view shows abstract circles and lines in shades of purple and blue with the text “SHOPIFY” in a sans serif typeface on top.",
              aspect_ratio: 1.0,
              height: 1600,
              url: "https://cdn.shopify.com/s/files/1/0565/6995/4403/products/Main_589fc064-24a2-4236-9eaf-13b2bd35d21d.jpg?v=1706629714",
              width: 1600
            },
            image: "https://cdn.shopify.com/s/files/1/0565/6995/4403/products/Main_589fc064-24a2-4236-9eaf-13b2bd35d21d.jpg?v=1706629714",
            handle: "the-complete-snowboard",
            requires_shipping: true,
            product_type: "snowboard",
            product_title: "The Complete Snowboard",
            untranslated_product_title: "The Complete Snowboard",
            product_description: "This PREMIUM snowboard is so SUPERDUPER awesome!",
            variant_title: "Ice",
            untranslated_variant_title: "Ice",
            variant_options: ["Ice"],
            options_with_values: [{"name": "Color", "value": "Ice"}],
            line_level_discount_allocations: [],
            line_level_total_discount: 0
          }
        ]
      }
    };
  },

  getCartResponseForLocalhost() {
    return { data: {
        "token": "Z2NwLWV1cm9wZS13ZXN0NDowMUhZWFhHNURGOFRaR1BFMEFYNlRZV1AxUQ",
        "note": "",
        "attributes": {
          "REEL_TOKK_JOURNEY_0765eb31-7782-4024-8873-4fa36d73bcf0": "0765eb31-7782-4024-8873-4fa36d73bcf0",
          "REEL_TOKK_SCENE_0b296918-bd97-476f-9d3d-84afbcb9c275": "0b296918-bd97-476f-9d3d-84afbcb9c275",
          "REEL_TOKK_EVENT_ADDED_TO_CART": "ADDED_TO_CART",
          "REEL_TOKK_EVENT_ADD_TO_CART": "ADD_TO_CART"
        },
        "original_total_price": 2999,
        "total_price": 2999,
        "total_discount": 0,
        "total_weight": 0.0,
        "item_count": 1,
        "items": [{
          "id": 47923046908247,
          "properties": {},
          "quantity": 1,
          "variant_id": 47923046908247,
          "key": "47923046908247:a645ff063a9a36dca9ca31d8a252176c",
          "title": "7\/8 High-Waist Airbrush Legging - Small",
          "price": 2999,
          "original_price": 2999,
          "presentment_price": 29.99,
          "discounted_price": 2999,
          "line_price": 2999,
          "original_line_price": 2999,
          "total_discount": 0,
          "discounts": [],
          "sku": "",
          "grams": 0,
          "vendor": "Daynamic Creatos",
          "taxable": true,
          "product_id": 8948846002519,
          "product_has_only_default_variant": false,
          "gift_card": false,
          "final_price": 2999,
          "final_line_price": 2999,
          "url": "\/products\/7-8-high-waist-airbrush-legging?variant=47923046908247",
          "featured_image": {
            "aspect_ratio": 0.667,
            "alt": "7\/8 High-Waist Airbrush Legging",
            "height": 1395,
            "url": "https:\/\/cdn.shopify.com\/s\/files\/1\/0812\/4743\/7143\/files\/W5604R_04570_b2_s1_a1_m18_1500x_84ac6e82-f8b0-4847-a07c-bfede1a4b54e.jpg?v=1711641050",
            "width": 930
          },
          "image": "https:\/\/cdn.shopify.com\/s\/files\/1\/0812\/4743\/7143\/files\/W5604R_04570_b2_s1_a1_m18_1500x_84ac6e82-f8b0-4847-a07c-bfede1a4b54e.jpg?v=1711641050",
          "handle": "7-8-high-waist-airbrush-legging",
          "requires_shipping": true,
          "product_type": "",
          "product_title": "7\/8 High-Waist Airbrush Legging",
          "product_description": "DESCRIPTION\u00a0\nThe 7\/8 High-Waist Airbrush Legging \u2014 all the smoothing, sculpting benefits of the full-length version, in a perfectly cropped package. So good for studio \u0026 all-day cool for street, this look features flat-locked seaming for comfort and functionality, no side seams, and an on-trend high waist.*PRODUCT FOR\u00a0 PREVIEW ONLY*",
          "variant_title": "Small",
          "variant_options": ["Small"],
          "options_with_values": [{"name": "Size", "value": "Small"}],
          "line_level_discount_allocations": [],
          "line_level_total_discount": 0,
          "has_components": false
        }],
        "requires_shipping": true,
        "currency": "EUR",
        "items_subtotal_price": 2999,
        "cart_level_discount_applications": []
      } }
  }
}
