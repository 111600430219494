export const state = {
  playingVideoPublicId: null,
};

export const getters = {
  playingVideoPublicId: state => state.playingVideoPublicId,
};

export const mutations = {
  SET_PLAYING_VIDEO_PUBLIC_ID(state, playingVideoPublicId) {
    state.playingVideoPublicId = playingVideoPublicId;
  },
}

export const actions = {
  setPlayingVideoPublicId({ commit }, playingVideoPublicId) {
    commit('SET_PLAYING_VIDEO_PUBLIC_ID', playingVideoPublicId);
  },
}

export const namespaced = true;
