import HomeIndex from "@/views/HomeIndex.vue";

export default [
  {
    path: '',
    name: 'HomeIndex',
    component: HomeIndex,
  },
  {
    path: '/',
    name: 'HomeIndex',
    component: HomeIndex,
  },
  {
    path: '*',
    name: 'HomeIndex',
    component: HomeIndex,
  },
];
