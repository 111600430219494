<template>
  <div class="shopify-product-story" v-if="product">
    <div class="product-wrapper">
      <div class="product-header">
        <!-- Back to collection button -->
        <button v-if="withBackArrow" @click="handleBackClick" class="back-to-collection">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
        </button>

        <div :style="{ paddingBottom: '8px', paddingTop: withBackArrow ? '16px' : '8px' }">

          <div class="pricing" v-if="selectedVariant">
            <p class="discounted-price">{{ getCurrencyCodeBeforeNumber(selectedVariant.priceV2.currencyCode) }}{{ formatPrice(selectedVariant.priceV2.amount * productQuantity, selectedVariant.priceV2.currencyCode) }}{{ getCurrencyCodeAfterNumber(selectedVariant.priceV2.currencyCode) }}</p>
<!--            <p class="price" v-if="getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount * productQuantity, product.priceRange.minVariantPrice.currencyCode, productQuantity)">{{ getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount * productQuantity, product.priceRange.minVariantPrice.currencyCode, productQuantity) }}</p>-->
          </div>

          <!-- Title -->
          <h3 class="product-title">{{ product?.title }}</h3>
        </div>
      </div>

      <!-- Image Carousel -->
      <div style="margin-top: 20px" v-if="product && product.images.nodes.length > 0">
        <ShopifyProductImageCarousel :images="product.images.nodes" />
      </div>

      <!-- Options -->
      <div v-if="product.options && product.options.length > 0">
        <div v-for="(option, optionIndex) in product.options" :key="option.id">
          <div v-if="option.name === 'Title' && option.values && option.values.length === 1 && option.values[0] === 'Default Title'">
          </div>
          <div v-else>
            <h4 :style="{ marginTop: optionIndex === 0 ? `0px` : `20px`, marginBottom: '4px', fontWeight: 700 }">{{ option.name }}</h4>

            <select
                class="select-input"
                @change="(e) => handleSelectedOptionChange(e, option, optionIndex)">
              <option v-for="optionValue in option.values" :key="optionValue" :value="optionValue">{{ optionValue }}</option>
            </select>
          </div>
        </div>
      </div>

      <!-- <h4 class="font-bold mt-5 mb-1">Variants</h4> -->

      <!-- Quantity -->
      <h4 style="font-weight: 700; margin-top: 20px; margin-bottom: 4px">Quantity</h4>

      <div v-if="selectedVariant && selectedVariant.quantityAvailable === 0">
        <p style="color: rgb(107, 114, 128)">Out of stock</p>
      </div>

      <div class="quantity-input-wrapper" v-else-if="selectedVariant && selectedVariant.quantityAvailable > 1">
        <button @click="decrementQuantity()" type="button" class="decrementQuantityButton">
          <svg style="width: 12px; height: 12px; color: rgb(17, 24, 39)" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
          </svg>
        </button>

        <input
          type="number"
          class="quantity-input"
          v-model="productQuantity"
          disabled
        >

        <button @click="incrementQuantity()" type="button" class="incrementQuantityButton">
          <svg style="width: 12px; height: 12px; color: rgb(17, 24, 39)" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
          </svg>
        </button>
      </div>

      <div v-else>
        <p>Only 1 left in stock</p>
      </div>

      <!-- Description -->
      <div v-if="product.description && product.description.length > 0">
        <h4 style="font-weight: 700; margin-top: 20px; margin-bottom: 4px">Description</h4>
        <p>{{ product.description }}</p>
      </div>
    </div>

    <!-- Buttons -->
    <div class="product-footer">
<!--      <button type="button" @click="openProductPage" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-md text-sm px-5 py-2.5 mr-3 my-3">-->
<!--        More info-->
<!--      </button>-->

      <button v-if="isActionButtonLoading" class="cta-button button-loading">
        <div class="circle-loader"></div>
      </button>

      <template v-else>
        <button v-if="isAvailableForSale" type="button" @click="handleActionButtonClick" class="cta-button">
          {{ actionButtonText }}
        </button>

        <button v-else disabled type="button" class="cta-button button-disabled">
          {{ actionButtonText }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import { getLargestCompareAtPrice } from '@/mixins/getLargestCompareAtPrice';
import { objectToQueryParams } from '@/helpers/object';
import { createOptionsKey, updateCartBubbleForStore } from '@/helpers/cart';
import storefront from '@/api/repositories/storefront';
import ShopifyProductImageCarousel from '@/components/ShopifyProductImageCarousel.vue';

export default {
  name: 'ShopifyProductStory',
  components: {ShopifyProductImageCarousel},
  mixins: [getLargestCompareAtPrice],
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    product: {
      type: Object | Array,
      required: true
    },
    withBackArrow: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      productQuantity: 1,
      selectedOptions: {},
      optionsOrder: {},
      isActionButtonLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      storeDomain: 'journeys/storeDomain',
    }),
    isAvailableForSale() {
      return this.selectedVariant && this.selectedVariant.availableForSale && this.selectedVariant.quantityAvailable > 0;
    },
    actionButtonText() {
      if (!this.isAvailableForSale) {
        return 'Not available';
      }

      return this.scene.action.label;
    },
    selectedVariantKey() {
      if (this.optionsOrder.length === 0) {
        return null;
      }

      let key = '';

      Object.keys(this.optionsOrder).sort().forEach(index => {
        const optionValue = this.optionsOrder[index];

        key += optionValue + ' / ';
      });

      return key.slice(0, -3);

    },
    selectedVariant() {
      if (!this.product) {
        return null;
      }

      if (!this.product.variants || !this.product.variants.nodes || this.product.variants.nodes.length === 0) {
        return null;
      }

      return this.product.variants.nodes.find(node => node.title === this.selectedVariantKey);
    },
    selectedVariantId() {
      if (!this.selectedVariant) {
        return null;
      }

      let variantId = null;

      try {
        variantId = this.selectedVariant.id.split('ProductVariant')[1].substring(1);
      } catch (e) {
        console.error(e);
      }

      return variantId;
    }
  },
  created() {
    this.initProductOptions();
  },
  methods: {
    initProductOptions() {
      if (!this.product) {
        this.optionsOrder = {};
        return;
      }

      const optionsOrder = {};

      this.product.options.forEach((option, index) => {
        optionsOrder[index] = option.values[0];
      });

      this.optionsOrder = optionsOrder;
    },
    handleSelectedOptionChange(e, option, optionIndex) {
      const optionsOrder = {...this.optionsOrder};

      optionsOrder[optionIndex] = e.target.value;

      this.optionsOrder = optionsOrder;
    },
    incrementQuantity() {
      if (this.productQuantity === this.selectedVariant.quantityAvailable) {
        return;
      }

      this.productQuantity = this.productQuantity + 1;
    },
    decrementQuantity() {
      if (this.productQuantity === 1) {
        return;
      }

      this.productQuantity = this.productQuantity - 1;
    },
    openProductPage() {
      const variantId = this.selectedVariantId;

      if (variantId !== null) {
        window.open(`https://${this.storeDomain}/products/${this.product.handle}?variant=${variantId}`, '_blank');
        return;
      }

      window.open(`https://${this.storeDomain}/products/${this.product.handle}`, '_blank');
    },
    async handleActionButtonClick() {
      if (!this.selectedVariantId || !this.isAvailableForSale) {
        return;
      }

      this.isActionButtonLoading = true;
      const actionType = this.scene.action.type;
      const customCartAttributes = this.getCustomCartAttributes(actionType);

      if (actionType !== 'CHECKOUT') {
        try {
          await storefront.setCartAttributes(customCartAttributes);
        } catch (e) {
          console.error(e);
        }
      }

      if (actionType === 'ADD_TO_CART') {
        try {
          await storefront.addToCart({
            id: this.selectedVariantId,
            quantity: this.productQuantity,
          });

          await this.addToReelTokCart();

          this.$store.dispatch('util/setSuccessToast', 'Added to cart');

          document.documentElement.dispatchEvent(new CustomEvent('cart:refresh', {
            bubbles: true,
          }));

          const { data } = await storefront.getCart();

          const cartItemsCount = data.item_count;
          const storeDomain = window.location.hostname;

          updateCartBubbleForStore(storeDomain, cartItemsCount);
        } catch (e) {
          console.error('defaultAddToCart() error', e);
        } finally {
          this.isActionButtonLoading = false;
        }
      }

      if (actionType === 'DIRECT_TO_CART') {
        try {
          await storefront.addToCart({
            id: this.selectedVariantId,
            quantity: this.productQuantity,
          });

          window.location.href = `https://${this.storeDomain}/cart`;
          this.isActionButtonLoading = false;
          return;
        } catch(e) {
          console.error(e);
          this.isActionButtonLoading = false;
        }
      }

      if (actionType === 'CHECKOUT') {
        const queryParams = objectToQueryParams(customCartAttributes);
        window.location.href = `https://${this.storeDomain}/cart/${this.selectedVariantId}:${this.productQuantity}?${queryParams}`;
      }
    },
    async addToReelTokCart() {
      const options = {};

      this.product.options.forEach((option, optionIndex) => {
        if (option.name === 'Title' && option.values && option.values.length === 1 && option.values[0] === 'Default Title') {
          return;
        }

        options[option.name] = this.optionsOrder[optionIndex];
      });

      const product = {
        title: this.product.title,
        image: this.product.images.nodes[0].src,
        quantity: this.productQuantity,
        selectedVariant: this.selectedVariant,
        options,
        optionsKey: createOptionsKey(options), // need options key to know when to increment quantity and when to add new product
      };

      await this.$store.dispatch('cart/addItem', product);
    },
    getCustomCartAttributes(actionType) {
      const attributes = {};
      const journeyUuid = this.journey.uuid;
      const sceneUuid = this.scene.uuid;

      let event = 'ADD_TO_CART';

      if (actionType === 'DIRECT_TO_CART') {
        event = 'GO_TO_CART';
      }

      if (actionType === 'CHECKOUT') {
        event = 'GO_TO_CHECKOUT';
      }

      attributes[`REEL_TOKK_JOURNEY_${journeyUuid}`] = journeyUuid;
      attributes[`REEL_TOKK_SCENE_${sceneUuid}`] = sceneUuid;
      attributes[`REEL_TOKK_EVENT_ADDED_TO_CART`] = 'ADDED_TO_CART';
      attributes[`REEL_TOKK_EVENT_${event}`] = event;

      return attributes;
    },
    handleBackClick() {
      this.$emit('backClick');
    }
  }
}
</script>
