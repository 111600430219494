<template>
  <div class="carousel-format" :style="{ minWidth: `calc((${carouselWidth}px + ${carouselSpacing}px) * ${journey.scenes.length})`, marginTop: '16px', marginBottom: '16px' }">
    <div v-for="(scene, sceneIndex) in journey.scenes" :key="scene.id" class="scene" :style="{ marginRight: sceneIndex === lastSceneIndex ? '0px' : `${carouselSpacing}px` }">
      <div class="video-player-wrapper">
        <div class="simple-video-player-wrapper" :style="{ width: `${carouselWidth}px`, height: `${carouselHeight}px` }">
          <SimpleVideoPlayer
            v-if="isAutoplayAtTheSameTimeEnabled || (isAutoplayEnabled && sceneIndex === autoPlayingSceneIndex)"
            class="simple-video-player"
            :style="{ width: `${carouselWidth}px`, height: `${carouselHeight}px` }"
            :scene="scene"
            :cloudName="cloudName"
            :publicId="scene.video.public_id"
            :rounded="true"
          />

          <div
            v-else
            class="thumbnail"
            :style="{ width: `${carouselWidth}px`, height: `${carouselHeight}px` }"
            v-html="getThumbnailComponent(scene)"
          >
          </div>

          <div
            class="scene-button"
            :style="{ width: `${carouselWidth}px`, height: `${carouselHeight}px` }"
            @click="handleActionButtonClick(scene.id, scene.uuid)"
          >
            <span
              class="play-button"
              v-if="sceneIndex !== autoPlayingSceneIndex && !isAutoplayAtTheSameTimeEnabled"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"/>
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div class="scene-text" v-if="scene.text && scene.text.length > 0" :style="{ width: `${carouselWidth}px` }">
        <small :style="useDefaultSceneTextColor ? 'color: #000000;' : `color: ${journey.config.scene_text_color}`">{{ scene.text }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SimpleVideoPlayer from '@/components/video-players/SimpleVideoPlayer.vue';
import {getThumbnailSrcForScene, getVideoSrcForScene} from '@/helpers/video';

const defaultCarouselWidth = 160;
const defaultCarouselHeight = 284;

const carouselMarginRight = 20;

export default {
  name: 'CarouselFormat',
  components: {SimpleVideoPlayer},
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      windowWidth: 'util/windowWidth',
      textDirection: 'util/textDirection',
    }),
    lastSceneIndex() {
      if (this.textDirection === 'ltr') {
        return this.journey.scenes.length - 1;
      }

      return 0;
    },
    isMobileWidth() {
      return this.windowWidth < 426;
    },
    sceneWidth() {
      if (!this.hasJourneyConfig) {
        return null;
      }

      if (this.isMobileWidth) {
        return this.journey.config?.mobile_scene_width;
      }

      return this.journey.config.scene_width;
    },
    sceneSpacing() {
      if (!this.hasJourneyConfig) {
        return null;
      }

      if (this.isMobileWidth) {
        return this.journey.config?.mobile_scene_spacing;
      }

      return this.journey.config.scene_spacing;
    },
    carouselWidth() {
      if (this.sceneWidth != null) {
        return this.sceneWidth;
      }

      return defaultCarouselWidth;
    },
    carouselHeight() {
      const aspectRatio = defaultCarouselWidth / defaultCarouselHeight;
      return this.carouselWidth / aspectRatio;
    },
    carouselSpacing() {
      if (this.sceneSpacing != null) {
        return this.sceneSpacing;
      }

      return carouselMarginRight;
    },
    hasJourneyConfig() {
      return this.journey.config && Object.keys(this.journey.config).length > 0;
    },
    useDefaultSceneTextColor() {
      if (!this.hasJourneyConfig) {
        return true;
      }
      return !this.journey.config.scene_text_color || this.journey.config.scene_text_color.length === 0;
    },
    isAutoplayEnabled() {
      if (!this.hasJourneyConfig) {
        return false;
      }

      return this.journey.config.autoplay;
    },
    isAutoplayAtTheSameTimeEnabled() {
      if (!this.hasJourneyConfig) {
        return false;
      }

      return this.journey.config.autoplay_at_same_time;
    },
  },
  data() {
    return {
      autoPlayInterval: null,
      autoPlayingSceneIndex: null,
      maxAutoplayLoops: 2, // stop autoplaying after this many loops
      loopCounter: 0,
    }
  },
  props: {
    journey: {
      type: Object,
      required: true
    },
  },
  created() {
    if (this.isAutoplayEnabled && !this.isAutoplayAtTheSameTimeEnabled) {
      this.autoPlayingSceneIndex = 0;

      this.autoPlayInterval = setInterval(() => {
        if (this.autoPlayingSceneIndex === this.journey.scenes.length - 1) {
          this.loopCounter += 1;

          if (this.loopCounter === this.maxAutoplayLoops) {
            this.autoPlayingSceneIndex = null;
            clearInterval(this.autoPlayInterval);
            return;
          }

          this.autoPlayingSceneIndex = 0;
        } else {
          this.autoPlayingSceneIndex++;
        }
      }, 8000);
    }
  },
  destroyed() {
    clearInterval(this.autoPlayInterval);
  },
  methods: {
    getThumbnailComponent(scene) {
      const thumbnailSrc = getThumbnailSrcForScene(scene);

      if (thumbnailSrc) {
        return `
          <div class="image-wrapper">
            <img src="${thumbnailSrc}" alt="Scene">
          </div>
        `;
      }

      const videoSrc = getVideoSrcForScene(scene);
      const style = `width: ${this.carouselWidth}px; height: ${this.carouselHeight}px; object-fit: cover;`;

      return `
        <video class="simple-video-player" muted style="${style}">
          <source src="${videoSrc}" />
        </video>
      `;
    },
    handleActionButtonClick(sceneId, sceneUuid) {
      this.$emit('click', {journey: this.journey, sceneId, sceneUuid});
      clearInterval(this.autoPlayInterval);
    },
  }
}
</script>


