import api from '@/api/api';

import {isLocalhost, routePrefix} from '@/helpers/api';

export default {
  getJourney(journeyId) {
    if (isLocalhost) {
      return api.get(`${routePrefix}/api/v1/public/journeys/${journeyId}?thpkykppvve=2fy4n2c9ub0dtrif5tciy99xxt`, {
        headers: {
          'X-qopcgcxebki': '5i06r9ovpfd44uzer0vpkyci6'
        }
      });
    }

    return api.get(`${routePrefix}/api/v1/public/journeys/${journeyId}`);
  },

  trackImpressions(journeyId, storefrontAccessToken, sceneIds) {
    if (isLocalhost) {
      console.log('trackImpressions: ', sceneIds);
      return;
    }

    return api.post(`${routePrefix}/api/v1/public/journeys/${journeyId}/impressions`, {
      scenes: sceneIds,
      storefrontAccessToken: storefrontAccessToken,
    });
  },

  trackClick(sceneUuid) {
    if (isLocalhost) {
      console.log('trackClick: ', sceneUuid);
      return;
    }

    return api.post(`${routePrefix}/api/v1/public/scenes/${sceneUuid}/clicks`);
  }
}
