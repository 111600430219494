export const getThumbnailSrcForScene = (scene, size = '') => {
  const customThumbnail = scene.thumbnail || scene.shopify_image_url || scene?.video.shopify_image_url;

  if (customThumbnail && customThumbnail.length > 0) {
    return customThumbnail;
  }

  const thumbnailUrl = scene.video?.thumbnail_url || null;

  if (thumbnailUrl && thumbnailUrl.length > 0) {
    return thumbnailUrl.replace('h_60,w_90', size);
  }

  return null;
};

export const getVideoSrcForScene = scene => {
  const instagramSrc = scene.video.instagram_src;

  if (instagramSrc && instagramSrc.length > 0) {
    return instagramSrc;
  }

  const shopifyVideo = scene.video.shopify_video;

  if (!shopifyVideo) {
    return null;
  }

  return shopifyVideo.sources[0].url;
};
