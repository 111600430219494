<template>
  <div class="shopify-item-mobile-story">
    <div class="product-card" @click="openProductCard" @touchstart="(e) => { e.preventDefault(); openProductCard(); }" v-if="shopifyItems">
      <div v-html="productCardImage"></div>

      <div style="display: flex; justify-content: space-between; width: 100%;">
        <div style="display: flex; flex-direction: column; justify-content: center; color: #FFFFFF; flex-grow: 1; padding-left: 12px; padding-right: 12px; ">
          <p class="item-title" :style="itemTitleStyle">{{ productCardTitle }}</p>
          <div style="display: flex">
            <p class="item-price" :style="itemPriceStyle">{{ productPrice }}</p>

<!--            <div v-if="showSaleBadge()" style="margin-left: 8px; display: flex; flex-direction: column; justify-content: center;">-->
<!--              <div class="sale-badge">Sale</div>-->
<!--            </div>-->
          </div>
        </div>

        <div class="shop-button-wrapper">
          <button class="shop-button">Shop</button>
        </div>
      </div>
    </div>

    <div ref="popup" v-show="isShopifyItemCardOpen || isCartOpen" class="popup-wrapper">
      <div class="popup">
        <MobileCart @closeCart="onCartClose" v-if="isCartOpen" />

        <template v-else>
          <ShopifyProductMobileStory
            v-if="isShopifyProduct"
            @closeShopifyItemCard="closeShopifyItemCard"
            :scene="scene"
            :journey="journey"
            :product="shopifyItems"
            @goToCart="onGoToCart"
            :is-window-mode="isWindowMode"
          />

          <ShopifyCollectionMobileStory
            v-else-if="isShopifyCollection"
            @closeShopifyItemCard="closeShopifyItemCard"
            @goToCart="onGoToCart"
            :scene="scene"
            :journey="journey"
            :collection="shopifyItems"
            :is-window-mode="isWindowMode"
          />
        </template>
      </div>
    </div>

    <VideoPlayer
      :scene="scene"
      @sceneClick="onSceneClick"
      @pause="onVideoPause"
      @play="onVideoPlay"
      @unmute="onUnmute"
      @mute="onMute"
      @close="onClose"
      @goToPrevScene="onPrevSceneClick"
      @goToNextScene="onNextSceneClick"
      @goToCart="onGoToCart"
      class="video-player-mobile"
      :is-playing="isPlaying"
      :is-muted="isMuted"
      :cloudName="cloudName"
      :publicId="scene.video.public_id"
      :playerConfig="{
        loop: true,
        muted: true,
        controls: false,
        bigPlayButton: false,
        responsive: true,
        aspectRatio: '9:16',
        fluid: true,
      }"
      :sourceConfig="{}"
      :show-watermark="showWatermark"
      :show-mobile-scene-controls="showMobileSceneControls"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ShopifyProductMobileStory from '@/components/stories/Shopify/mobile/ShopifyProductMobileStory.vue';
import ShopifyCollectionMobileStory from '@/components/stories/Shopify/mobile/ShopifyCollectionMobileStory.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import MobileCart from '@/components/MobileCart.vue';

export default {
  name: 'ShopifyItemMobileStory',
  components: {MobileCart, ShopifyCollectionMobileStory, VideoPlayer, ShopifyProductMobileStory},
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
    }),
    isShopifyProduct() {
      const actionConfig = this.scene.action.config;

      if (actionConfig.type === 'collection') {
        return false;
      }

      if ('ids' in actionConfig) {
        return actionConfig.ids.length === 1;
      }

      return true;
    },
    isShopifyCollection() {
      return !this.isShopifyProduct;
    },
    productCardImage() {
      if (!this.shopifyItems) {
        return null;
      }

      if ('featuredImage' in this.shopifyItems || 'image' in this.shopifyItems) {
        const src = this.shopifyItems.featuredImage ? this.shopifyItems.featuredImage.src : (this.shopifyItems.image ? this.shopifyItems.image.src : '');
        const alt = this.shopifyItems.featuredImage ? this.shopifyItems.featuredImage.altText : (this.shopifyItems.image ? this.shopifyItems.image.altText : '');

        if (!src || src.length === 0) {
          return null;
        }

        return `<img src="${src}" alt="${alt}">`;
      }

      return null;
    },
    itemTitleStyle() {
      if (!this.productCardTitle) {
        return {};
      }

      let fontSize = '10px';

      if (this.productCardTitle.length < 15) {
        fontSize = '12px';
      }

      if (this.productCardImage) {
        return { fontSize };
      }

      return { fontSize: '14px' }
    },
    itemPriceStyle() {
      if (this.productCardTitle) {
        if (this.productCardImage) {
          return { fontSize: '10px' };
        }

        return { fontSize: '14px' };
      }

      return { fontSize: '14px' };
    },
    productCardTitle() {
      if (!this.shopifyItems) {
        return null;
      }

      if ('title' in this.shopifyItems) {
        const title = this.shopifyItems.title;

        return title.length > 29 ? title.slice(0, 29) + '...' : title
      }

      return null;
    },
    productPrice() {
      if (!this.shopifyItems) {
        return null;
      }

      if (this.isShopifyCollection) {
        return this.getPriceForCollection();
      }

      if (!this.shopifyItems.priceRange || !this.shopifyItems.priceRange.minVariantPrice) {
        return null;
      }

      const currency = this.shopifyItems.priceRange.minVariantPrice.currencyCode.toLowerCase();
      const price = this.shopifyItems.priceRange.minVariantPrice.amount;

      if (['usd', 'aud', 'cad', 'nzd', ].includes(currency)) {
        return `$${price}`;
      }

      if (currency === 'eur') {
        return `€${price}`;
      }

      if (currency === 'gbp') {
        return `£${price}`;
      }

      if (currency === 'jpy') {
        return `¥${price}`;
      }

      return `${price} ${currency}`;
    },
  },
  data() {
    return {
      isShopifyItemCardOpen: false,
    }
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    shopifyItems: {
      type: Object | Array,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    showWatermark: Boolean,
    showMobileSceneControls: {
      type: Boolean,
      default: false
    },
    isCartOpen: {
      type: Boolean,
      default: false
    },
    isWindowMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCartClose() {
      this.$emit('closeCart');
    },
    openProductCard() {
      this.isShopifyItemCardOpen = true;
      this.$store.dispatch('util/productPopupOpened', true);
    },
    closeShopifyItemCard() {
      this.isShopifyItemCardOpen = null;
      this.$store.dispatch('util/productPopupOpened', false);
    },
    onVideoPlay() {
      this.$emit('video-play', this.scene.id);
    },
    onSceneClick(scene) {
      this.$emit('scene-click', scene);
    },
    onVideoPause() {
      this.$emit('video-pause', this.scene.id);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onClose() {
      this.$emit('close');
    },
    onPrevSceneClick() {
      this.$emit('goToPrevScene');
    },
    onNextSceneClick() {
      this.$emit('goToNextScene');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    showSaleBadge() {
      if (this.isShopifyCollection) {
        return this.showSaleBadgeForCollection(this.shopifyItems);
      }

      return this.showSaleBadgeForProduct(this.shopifyItems);
    },
    showSaleBadgeForCollection(collection) {
      if (!collection?.products?.nodes || collection?.products?.nodes.length === 0) {
        return false;
      }

      for (const product of collection.products.nodes) {
        if (this.showSaleBadgeForProduct(product)) {
          return true;
        }
      }

      return false;
    },
    showSaleBadgeForProduct(item) {
      if (!item?.variants?.nodes || item?.variants?.nodes.length === 0) {
        return false;
      }

      if (!item.priceRange || !item.priceRange.minVariantPrice) {
        return false;
      }

      const currentPrice = item.priceRange.minVariantPrice.amount;

      let biggestVariantPrice = 0;

      item.variants.nodes.forEach(node => {
        if (node.compareAtPrice && node.compareAtPrice.amount) {
          const variantPrice = parseFloat(node.compareAtPrice.amount);

          if (variantPrice > biggestVariantPrice) {
            biggestVariantPrice = variantPrice;
          }
        }
      });

      if (biggestVariantPrice === 0) {
        return false;
      }

      return biggestVariantPrice > parseFloat(currentPrice);
    },
    getPriceForCollection() {
      if (!this.shopifyItems) {
        return null;
      }

      const products = 'products' in this.shopifyItems ? this.shopifyItems.products.nodes : this.shopifyItems;

      if (products.length === 0) {
        return null;
      }

      const currency = products[0].priceRange.minVariantPrice.currencyCode.toLowerCase();
      const collectionProductPrices = products.map(product => parseFloat(product.priceRange.minVariantPrice.amount));

      collectionProductPrices.sort((a, b) => a - b);

      const price = collectionProductPrices[0];

      if (['usd', 'aud', 'cad', 'nzd', ].includes(currency)) {
        return `From: $${price}`;
      }

      if (currency === 'eur') {
        return `From: €${price}`;
      }

      if (currency === 'gbp') {
        return `From: £${price}`;
      }

      if (currency === 'jpy') {
        return `From: ¥${price}`;
      }

      return `From: ${price} ${currency}`;
    },
  }
}
</script>
