<template>
  <div :id="`journey-${journeyId}`" class="reel-tok-viewer" ref="document" style="font-size: 16px; visibility: visible !important;">
    <router-view/>
    <Notification
      v-if="isToastVisible"
      style="z-index: 50; width: 100%; position: fixed; bottom: 35px; left:50%; transform: translateX(-50%); border-radius: 30px"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Notification from '@/components/Notification.vue';

export default {
  name: 'App',
  components: { Notification },
  computed: {
    ...mapGetters({
      journeyId: 'journeys/journeyId',
      isToastVisible: 'util/isToastVisible'
    }),
  },
}
</script>
