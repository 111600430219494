<template>
  <div class="simple-button-mobile-story">
    <div v-if="showActionButton" class="button-wrapper">
      <button
        @click="handleActionButtonClick"
        @touchstart="(e) => { e.preventDefault(); handleActionButtonClick(); }"
        class="simple-link-button">
        {{ scene.action.label }}
      </button>
    </div>

    <div ref="popup" v-show="isCartOpen" class="popup-wrapper">
      <div class="popup">
        <MobileCart @closeCart="onCartClose" v-if="isCartOpen" />
      </div>
    </div>

    <VideoPlayer
      :scene="scene"
      @sceneClick="onSceneClick"
      @pause="onVideoPause"
      @play="onVideoPlay"
      @unmute="onUnmute"
      @mute="onMute"
      @close="onClose"
      @goToPrevScene="onPrevSceneClick"
      @goToNextScene="onNextSceneClick"
      @goToCart="onGoToCart"
      class="video-player-mobile"
      :is-playing="isPlaying"
      :is-muted="isMuted"
      :cloudName="cloudName"
      :publicId="scene.video.public_id"
      :playerConfig="{
        loop: true,
        muted: true,
        controls: false,
        bigPlayButton: false,
        responsive: true,
        aspectRatio: '9:16',
        fluid: true,
      }"
      :sourceConfig="{}"
      :show-watermark="showWatermark"
      :show-mobile-scene-controls="showMobileSceneControls"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoPlayer from '@/components/VideoPlayer.vue';
import MobileCart from '@/components/MobileCart.vue';

export default {
  name: 'SimpleButtonMobileStory',
  components: {MobileCart, VideoPlayer},
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName',
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
    }),
    showActionButton() {
      return this.scene.action && this.scene.action.config && this.scene.action.config.link && this.scene.action.config.link.length > 0;
    },
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    showWatermark: Boolean,
    showMobileSceneControls: {
      type: Boolean,
      default: false
    },
    isCartOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onCartClose() {
      this.$emit('closeCart');
    },
    onSceneClick(scene) {
      console.log('clicked scene', scene);
      // this.$emit('scene-click', scene); not working
    },
    onVideoPlay() {
      this.$emit('video-play', this.scene.id);
    },
    onVideoPause() {
      this.$emit('video-pause', this.scene.id);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onClose() {
      this.$emit('close');
    },
    onPrevSceneClick() {
      this.$emit('goToPrevScene');
    },
    onNextSceneClick() {
      this.$emit('goToNextScene');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    handleActionButtonClick() {
      this.onVideoPause();
      this.onClose();
      window.location.href = this.scene.action.config.link;
    }
  }
}
</script>
