<template>
  <div id="toast-success" class="toast-success" role="alert">
    <div class="badge">
      <svg aria-hidden="true" style="width: 20px; height: 20px" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
    </div>

    <div class="message-wrapper">
      <div class="message">{{ message }}</div>
    </div>

    <button type="button" class="close-button" data-dismiss-target="#toast-success" aria-label="Close" @click="onClose()">
<!--      <span class="sr-only">Close</span>-->
      <svg aria-hidden="true" style="width: 20px; height: 20px" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'toast-success',
  props: ['message'],
  methods: {
    onClose() {
      this.$emit('onClose');
    }
  }
}
</script>
