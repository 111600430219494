<template>
  <ToastSuccess v-if="toast.type === 'success'" :message="toast.message" @onClose="onClose()" />
</template>

<script>
import { mapGetters } from "vuex";
import ToastSuccess from "@/components/Toast/ToastSuccess.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'notification',
  components: { ToastSuccess },
  computed: {
    ...mapGetters({
      toast: 'util/toast'
    })
  },
  methods: {
    onClose() {
      this.$store.dispatch('util/dismissToast');
    }
  }
}
</script>
