<template>
  <div class="home">
    <StoryViewer
      v-if="isStoryViewerOpen"
      :journey="selectedJourney"
      :selected-scene-index="selectedJourneySceneIndex"
      @close="closeStoryViewer"
      @story-changed="handleOnStoryChanged"
    />

    <WindowedStoryViewer
      v-if="isWindowedStoryViewerOpen"
      :journey="selectedJourney"
      :selected-scene-index="selectedJourneySceneIndex"
      @close="closeStoryViewer"
      @story-changed="handleOnStoryChanged"
      :portal-key="portalKey"
    />

    <div v-show="!isStoryViewerOpen && showJourney" class="format-wrapper">
      <div class="arrows-wrapper" v-if="showArrows">
        <div class="arrow-left" @click="handleOnArrowClick('left')">
          <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path fill-rule="evenodd" d="M11.764 5.204a.75.75 0 0 1 .032 1.06l-3.516 3.736 3.516 3.736a.75.75 0 1 1-1.092 1.028l-4-4.25a.75.75 0 0 1 0-1.028l4-4.25a.75.75 0 0 1 1.06-.032Z"></path></svg>
        </div>
        <div class="arrow-right" @click="handleOnArrowClick('right')">
          <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path fill-rule="evenodd" d="M7.72 14.53a.75.75 0 0 1 0-1.06l3.47-3.47-3.47-3.47a.75.75 0 0 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0Z"></path></svg>
        </div>
      </div>

      <div :class="['format', showArrows ? 'with-arrows' : '']">
        <StoryFormat v-if="journey.type === 'STORY'" :journey="journey" @click="handleOnJourneyClick" />
        <CarouselFormat v-if="journey.type === 'CAROUSEL'" :journey="journey" @click="handleOnJourneyClick" />
        <FloatingFormat v-if="isFloatingFormat" :journey="journey" @click="handleOnJourneyClick" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import StoryFormat from '@/components/formats/StoryFormat.vue';
import CarouselFormat from '@/components/formats/CarouselFormat.vue';
import FloatingFormat from '@/components/formats/FloatingFormat.vue';
import StoryViewer from '@/components/StoryViewer.vue';
import repository from '@/api/repositories/repository';
import WindowedStoryViewer from '@/components/WindowedStoryViewer.vue';
import {isLocalhost} from '@/helpers/api';

const defaultCarouselWidth = 160;
const defaultStorySize = 100;

export default {
  name: 'HomeIndex',
  components: {WindowedStoryViewer, StoryViewer, StoryFormat, CarouselFormat, FloatingFormat},
  data() {
    return {
      portalKey: Date.now(),
      selectedJourney: null,
      selectedJourneySceneIndex: 0,

      isStoryViewerOpen: false,
      isWindowedStoryViewerOpen: false,
      isPlacementOverflowing: false,
    }
  },
  computed: {
    ...mapGetters({
      journey: 'journeys/journey',
      storefrontAccessToken: 'journeys/storefrontAccessToken',
      storeDomain: 'journeys/storeDomain',
      windowWidth: 'util/windowWidth',
      shadowRoot: 'util/shadowRoot',
    }),
    // showStoryViewer() {
    //   return this.selectedJourney !== null && !this.showWindowedStoryViewer;
    // },
    showJourney() {
      return this.journey !== null;
    },
    isFloatingFormat() {
      if (this.journey === null) {
        return false;
      }

      const floatingTypes = [
        'FLOATING_TOP_LEFT',
        'FLOATING_TOP_RIGHT',
        'FLOATING_BOTTOM_LEFT',
        'FLOATING_BOTTOM_RIGHT'
      ];

      return floatingTypes.includes(this.journey.type);
    },
    // showWindowedStoryViewer() {
    //   return this.selectedJourney !== null && this.isWindowedMode;
    // },
    isWindowedMode() {
      return this.journey.config.windowed_mode;
    },
    isMobileWidth() {
      return this.windowWidth < 426;
    },
    hasJourneyConfig() {
      return this.journey.config && Object.keys(this.journey.config).length > 0;
    },
    sceneWidthFromConfig() {
      if (!this.hasJourneyConfig) {
        return null;
      }

      if (this.isMobileWidth) {
        return this.journey.config?.mobile_scene_width;
      }

      return this.journey.config.scene_width;
    },
    sceneWidth() {
      if (this.sceneWidthFromConfig != null) {
        return this.sceneWidthFromConfig;
      }


      if (this.journey.type === 'STORY') {
        return defaultStorySize;
      }

      return defaultCarouselWidth;
    },
    showArrows() {
      if (this.isFloatingFormat || !this.hasJourneyConfig || !this.isPlacementOverflowing) {
        return false;
      }

      if (this.isMobileWidth) {
        return this.journey.config?.mobile_show_arrows === 1;
      }

      return this.journey.config.show_arrows === 1;
    },
  },
  created() {
    try {
      this.$store.commit('util/SET_TEXT_DIRECTION', this.getTextDirection());

      const cart = JSON.parse(localStorage.getItem('reeltok_cart') || '{}');

      if (cart.items) {
        this.$store.commit('cart/SET_ITEMS', cart.items);
      }

      if (this.isFloatingFormat) {
        const sceneUuids = this.journey.scenes.map(scene => scene.uuid);
        repository.trackImpressions(this.journey.uuid, this.storefrontAccessToken, [sceneUuids[0]]);
        return;
      }
      const sceneUuids = this.journey.scenes.map(scene => scene.uuid);

      repository.trackImpressions(this.journey.uuid, this.storefrontAccessToken, sceneUuids);
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    if (['STORY', 'CAROUSEL'].includes(this.journey.type)) {
      this.checkIfPlacementIsOverflowing();
    }

    window.addEventListener('resize', this.handleResize);

    const urlContainsScene = 'reeltok_scene' in this.$route.query;

    if (!urlContainsScene) {
      return;
    }

    const reeltokScene = this.$route.query.reeltok_scene;

    const reeltokSceneIndex = this.journey.scenes.findIndex(scene => scene.uuid === reeltokScene);

    if (reeltokSceneIndex === -1) {
      return;
    }

    this.selectedJourneySceneIndex = reeltokSceneIndex;
    this.selectedJourney = this.journey;
    this.$store.dispatch('util/setLandedDirectToScene', true);

    if (this.isWindowedMode) {
      this.isWindowedStoryViewerOpen = true;
      this.portalKey = Date.now();
    } else {
      this.isStoryViewerOpen = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$store.dispatch('util/setWindowWidth', window.innerWidth);
    },
    handleOnJourneyClick({journey, sceneId, sceneUuid}) {
      this.portalKey = Date.now();
      this.selectedJourneySceneIndex = journey.scenes.findIndex(scene => scene.id === sceneId) ?? 0;
      this.selectedJourney = journey;
      repository.trackImpressions(journey.uuid, this.storefrontAccessToken, [sceneUuid]);
      repository.trackClick(sceneUuid);
      this.$store.dispatch('cart/setJourneyAndScene', { journeyUuid: journey.uuid, sceneUuid });

      if (this.isWindowedMode) {
        this.isWindowedStoryViewerOpen = true;
      } else {
        this.isStoryViewerOpen = true;
      }
    },
    closeStoryViewer() {
      this.selectedJourney = null;
      this.isWindowedStoryViewerOpen = false;
      this.isStoryViewerOpen = false;

      const scriptTag = document.querySelector(`script[journey-id="${this.journey.uuid}"]`);
      const viewportHeight = window.innerHeight;
      const elementPosition = scriptTag.parentElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - (viewportHeight / 2) + (scriptTag.parentElement.offsetHeight / 2);

      window.scrollTo({top: offsetPosition, behavior: 'instant'});

      setTimeout(() => {
        window.scrollTo({top: offsetPosition, behavior: 'instant'});
      }, 50);
    },
    handleOnStoryChanged({ sceneUuid }) {
      repository.trackImpressions(this.journey.uuid, this.storefrontAccessToken, [sceneUuid]);
    },
    checkIfPlacementIsOverflowing() {
      const formatClass = this.journey.type === 'STORY' ? '.story-format' : '.carousel-format';

      let format = null;
      let reeltokWrapper = null;

      if (isLocalhost) {
        format = document.querySelector(formatClass);
        reeltokWrapper = document.getElementById(`journey-${this.journey.uuid}`);
      } else {
        format = this.shadowRoot.querySelector(formatClass);
        reeltokWrapper = this.shadowRoot.getElementById(`journey-${this.journey.uuid}`);
      }

      console.log('checkIfPlacementIsOverflowing 1', {format, reeltokWrapper});

      if (!format || !reeltokWrapper) {
        return;
      }

      const formatWidth = format.offsetWidth;
      const reeltokWrapperWidth = reeltokWrapper.offsetWidth;

      console.log('checkIfPlacementIsOverflowing 2', {
        formatWidth,
        reeltokWrapperWidth,
      });

      this.isPlacementOverflowing = formatWidth > reeltokWrapperWidth;
    },
    handleOnArrowClick(position) {
      const formatClass = this.journey.type === 'STORY' ? '.story-format' : '.carousel-format';
      let node = null;

      if (isLocalhost) {
        node = document.querySelector(formatClass)
      } else {
        node = this.shadowRoot.querySelector(formatClass)
      }

      if (!node || !node.parentNode) {
        console.error('ReelTok handleOnArrowClick -> node or node.parentNode is null');
        this.isPlacementOverflowing = false;
        return;
      }

      node.parentNode.scrollBy({
        left: position === 'left' ? (-1 * this.sceneWidth) : this.sceneWidth,
        behavior: 'smooth',
      });
    },

    getTextDirection() {
      try {
        return window.getComputedStyle(document.querySelector('body')).getPropertyValue('direction');
      } catch (e) {
        console.log('Error getting text direction', e);
        return 'ltr';
      }
    }
  },
}
</script>
