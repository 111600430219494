export const getLargestCompareAtPrice = {
  methods: {
    getLargestCompareAtPrice(nodes, currentPrice, currencyCode, productQuantity = 1) {
      if (nodes.length === 0) {
        return null;
      }

      let biggestVariantPrice = 0;

      nodes.forEach(node => {
        if (node.compareAtPrice && node.compareAtPrice.amount) {
          const variantPrice = parseFloat(node.compareAtPrice.amount);

          if (variantPrice > biggestVariantPrice) {
            biggestVariantPrice = variantPrice;
          }
        }
      });

      biggestVariantPrice = biggestVariantPrice * productQuantity;

      if (biggestVariantPrice === 0) {
        return null;
      }

      if (biggestVariantPrice <= parseFloat(currentPrice)) {
        return null;
      }

      const priceSuffix = this.getCurrencyCodeBeforeNumber(currencyCode);
      const pricePrefix = this.getCurrencyCodeAfterNumber(currencyCode);
      const price = this.formatPrice(biggestVariantPrice, currencyCode);

      return priceSuffix + price + pricePrefix;
    },
    getCurrencyCodeBeforeNumber(currencyCode) {
      if (currencyCode === 'EUR') {
        return '€';
      }

      if (currencyCode === 'USD' || currencyCode === 'AUD') {
        return '$';
      }

      if (currencyCode === 'GBP') {
        return '£';
      }

      if (currencyCode === 'JPY') {
        return '¥';
      }

      return ' ' + currencyCode;
    },

    getCurrencyCodeAfterNumber(currencyCode) {
      if (['EUR', 'USD', 'AUD', 'GBP', 'JPY'].includes(currencyCode)) {
        return '';
      }

      return ' ' + currencyCode;
    },
    formatPrice(price, currencyCode = null) {
      const factor = 100;
      const truncatedPrice = Math.floor(price * factor) / factor;

      const parts = truncatedPrice.toString().split('.');
      let integerPart = parts[0];
      let decimalPart = parts[1] || '';

      // Insert commas into integerPart
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Check if currencyCode requires two decimal places
      const currenciesWithTwoDecimals = ['EUR', 'USD', 'AUD', 'GBP'];
      const alwaysTwoDecimals = currenciesWithTwoDecimals.includes(currencyCode);

      if (alwaysTwoDecimals) {
        // Ensure decimalPart has exactly two digits
        decimalPart = decimalPart.padEnd(2, '0');
        return integerPart + '.' + decimalPart;
      }

      if (decimalPart === '' || Number(decimalPart) === 0) {
        return integerPart;
      }

      // Ensure decimalPart has exactly two digits
      decimalPart = decimalPart.padEnd(2, '0');

      return integerPart + '.' + decimalPart;
    }
  }
};