<template>
  <div class="cart-component">
    <div class="cart-header">
      <button @click="closeCart" class="close-cart">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
        </svg>
      </button>

      <h3 class="cart-title">My Cart</h3>
    </div>

    <div class="empty-cart-section" v-if="isCartEmpty">
      <div>
        <h4>Your cart is currently empty</h4>

        <div class="continue-shopping-wrapper">
          <button class="continue-shopping" @click="closeCart">Continue shopping</button>
        </div>
      </div>
    </div>

    <div v-else class="cart-items-wrapper">
      <div v-for="(item, index) in items" :key="index" class="cart-item">
        <div class="left">
          <div class="img-wrapper">
            <img :src="item.image" :alt="item.name">
          </div>
        </div>

        <div class="right">
          <h4 class="item-title">{{ item.title }}</h4>

          <div class="options-wrapper">
            <div class="options">
              <span>{{ getOptionsText(item.options) }}</span>
            </div>
          </div>

          <p class="price">{{ getCurrencyCodeBeforeNumber(item.selectedVariant.priceV2.currencyCode) }}{{ formatPrice(item.selectedVariant.priceV2.amount * item.quantity, item.selectedVariant.priceV2.currencyCode) }}{{ getCurrencyCodeAfterNumber(item.selectedVariant.priceV2.currencyCode) }}</p>

          <div class="quantity-picker">
            <div class="quantity-loader" v-if="loadingItemIndexes.includes(index)">
              <div class="circle-loader-wrapper">
                <div class="circle-loader"></div>
              </div>
            </div>

            <div class="controls">
              <div style="display: flex; flex-direction: column; justify-content: center">
                <button @click="decrementItemQuantity(index)">-</button>
              </div>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <span style="font-size: 16px;">{{ item.quantity }}</span>
              </div>
              <div style="display: flex; flex-direction: column; justify-content: center">
                <button @click="incrementItemQuantity(index)">+</button>
              </div>
            </div>
            <div class="remove-item" @click="removeItem(index)">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="padding: 6px;">
                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isCartEmpty" class="cart-footer">
      <div class="subtotal">
        <span>Subtotal</span>
        <span class="amount">{{ subtotal }}</span>
      </div>

      <button v-if="isActionButtonLoading" class="cta-button button-loading">
        <div class="circle-loader"></div>
      </button>

      <button v-else type="button" @click="handleActionButtonClick" class="cta-button">
        Checkout
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import storefront from '@/api/repositories/storefront';
import {getLargestCompareAtPrice} from '@/mixins/getLargestCompareAtPrice';
import {parseNumericValueFromVariantId, updateCartBubbleForStore} from '@/helpers/cart';
import {objectToQueryParams} from '@/helpers/object';

export default {
  name: 'cart',
  mixins: [getLargestCompareAtPrice],
  computed: {
    ...mapGetters({
      items: 'cart/items',
      itemsCount: 'cart/itemsCount',
      storeDomain: 'journeys/storeDomain',
      journeyUuid: 'cart/journeyUuid',
      sceneUuid: 'cart/sceneUuid',
    }),
    isCartEmpty() {
      return this.itemsCount === 0;
    },
    subtotal() {
      const amount = this.items.reduce((accumulator, item) => {
        return accumulator + item.selectedVariant.priceV2.amount * item.quantity;
      }, 0);

      const currencyCode = this.items[0].selectedVariant.priceV2.currencyCode;
      const priceSuffix = this.getCurrencyCodeAfterNumber(currencyCode);
      const pricePrefix = this.getCurrencyCodeBeforeNumber(currencyCode);
      const price = this.formatPrice(amount, currencyCode);

      return `${pricePrefix}${price}${priceSuffix}`;
    },
  },
  data() {
    return {
      isActionButtonLoading: false,
      loadingItemIndexes: []
    }
  },
  methods: {
    getOptionsText(options) {
      let text = '';

      for (const key in options) {
        const option = options[key];
        text += `${key}: ${option}; `;
      }

      return text.slice(0, -2);
    },
    closeCart() {
      this.$emit('closeCart');
    },
    async incrementItemQuantity(itemIndex) {
      try {
        this.loadingItemIndexes.push(itemIndex);

        const selectedVariantId = parseNumericValueFromVariantId(this.items[itemIndex].selectedVariant.id);

        await storefront.addToCart({
          id: selectedVariantId,
          quantity: 1,
        });

        document.documentElement.dispatchEvent(new CustomEvent('cart:refresh', {
          bubbles: true,
        }));

        this.$store.commit('cart/INCREMENT_ITEM_QUANTITY', itemIndex);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingItemIndexes = this.loadingItemIndexes.filter(index => index !== itemIndex);
      }

      this.tryUpdateStoreCartBubble().catch(e => console.error(e));
    },
    async decrementItemQuantity(itemIndex) {
      try {
        this.loadingItemIndexes.push(itemIndex);

        const item = this.items[itemIndex];
        const selectedVariantId = parseNumericValueFromVariantId(item.selectedVariant.id);

        let newQuantity = item.quantity - 1;

        if (newQuantity < 1) {
          newQuantity = 0;
        }

        await storefront.updateCart({
          id: selectedVariantId,
          quantity: newQuantity,
        });

        document.documentElement.dispatchEvent(new CustomEvent('cart:refresh', {
          bubbles: true,
        }));

        this.$store.commit('cart/DECREMENT_ITEM_QUANTITY', itemIndex);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingItemIndexes = this.loadingItemIndexes.filter(index => index !== itemIndex);
      }

      this.tryUpdateStoreCartBubble().catch(e => console.error(e));
    },
    async removeItem(itemIndex) {
      try {
        this.loadingItemIndexes.push(itemIndex);

        const selectedVariantId = parseNumericValueFromVariantId(this.items[itemIndex].selectedVariant.id);

        await storefront.updateCart({
          id: selectedVariantId,
          quantity: 0,
        });

        document.documentElement.dispatchEvent(new CustomEvent('cart:refresh', {
          bubbles: true,
        }));

        this.$store.commit('cart/REMOVE_ITEM', itemIndex);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingItemIndexes = this.loadingItemIndexes.filter(index => index !== itemIndex);
      }

      this.tryUpdateStoreCartBubble().catch(e => console.error(e));
    },
    async tryUpdateStoreCartBubble() {
      const { data } = await storefront.getCart();
      const cartItemsCount = data.item_count;
      const storeDomain = window.location.hostname;

      updateCartBubbleForStore(storeDomain, cartItemsCount);
    },
    async handleActionButtonClick() {
      try {
        this.isActionButtonLoading = true;

        const customCartAttributes = {};
        customCartAttributes[`REEL_TOKK_JOURNEY_${this.journeyUuid}`] = this.journeyUuid;
        customCartAttributes[`REEL_TOKK_SCENE_${this.sceneUuid}`] = this.sceneUuid;
        customCartAttributes[`REEL_TOKK_EVENT_ADDED_TO_CART`] = 'ADDED_TO_CART';
        customCartAttributes[`REEL_TOKK_EVENT_GO_TO_CHECKOUT`] = 'GO_TO_CHECKOUT';

        const variantToQuantityMapString = this.items.map(item => {
          const selectedVariantId = parseNumericValueFromVariantId(item.selectedVariant.id);
          return `${selectedVariantId}:${item.quantity}`;
        }).join(',');

        const queryParams = objectToQueryParams(customCartAttributes);

        window.location.href = `https://${this.storeDomain}/cart/${variantToQuantityMapString}?${queryParams}`;
      } catch (e) {
        console.error(e);
      } finally {
        this.isActionButtonLoading = false;
      }
    }
  },
}
</script>