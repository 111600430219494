import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import * as util from './modules/util';
import * as videos from './modules/videos';
import * as cart from './modules/cart';
import * as journeys from './modules/journeys';

export default new Vuex.Store({
  modules: {
    util,
    videos,
    cart,
    journeys,
  }
});
