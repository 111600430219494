<template>
  <div class="shopify-collection-mobile-story">
    <div v-if="selectedProduct === null" class="collection-wrapper">
      <div style="color: black; position: relative; margin-bottom: 20px;">
        <div class="collection-header" :style="{ borderBottomWidth: collectionTitle.length > 0 ? '1px;' : '0' }">
          <button @click="closeCollectionCard" @touchstart="(e) => { e.preventDefault(); closeCollectionCard(); }" class="close-collection-button">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"/>
            </svg>
          </button>

          <div :style="{ paddingTop: collectionTitle.length === 0 ? '16px' : '8px', paddingBottom: '8px' }">
            <p class="collection-subtitle">{{ collectionProducts.length }} products</p>
            <h3 class="collection-title">{{ collectionTitle }}</h3>
          </div>
        </div>

        <div class="collections">
          <div v-for="product in collectionProducts" :key="product.id" class="collection" :style="{width: `${collectionImageSize}px`}">
            <div class="collection-img-wrapper" :style="{ width: `${collectionImageSize}px`, height: `${collectionImageSize}px` }">
              <img @click="selectProduct(product)" class="collection-image" :src="getProductImageSource(product)" :alt="getProductImageAlt(product)">
            </div>

            <h4 @click="selectProduct(product)" class="product-title">{{ product.title }}</h4>

            <div style="display: flex; justify-content: start;">
              <div style="display: flex; flex-direction: column; justify-content: center;">
                <p style="font-size: 12px; color: rgb(17, 24, 39)">{{ getCurrencyCodeBeforeNumber(product.priceRange.minVariantPrice.currencyCode) }}{{ formatPrice(product.priceRange.minVariantPrice.amount, product.priceRange.minVariantPrice.currencyCode) }}{{ getCurrencyCodeAfterNumber(product.priceRange.minVariantPrice.currencyCode) }}</p>
              </div>

<!--              <div style="margin-left: 4px; display: flex; flex-direction: column; justify-content: center;" v-if="getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount, product.priceRange.minVariantPrice.currencyCode)">-->
<!--                <p style="color: rgb(107, 114, 128); font-size: 12px; text-decoration: line-through;">{{ getLargestCompareAtPrice(product.variants.nodes, product.priceRange.minVariantPrice.amount, product.priceRange.minVariantPrice.currencyCode) }}</p>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShopifyProductMobileStory
      v-else
      @closeShopifyItemCard="closeCollectionCard"
      @backClick="selectProduct(null)"
      @goToCart="onGoToCart"
      :with-back-arrow="true"
      :scene="scene"
      :journey="journey"
      :product="selectedProduct"
      :is-window-mode="isWindowMode"
    />
  </div>
</template>

<script>
import ShopifyProductMobileStory from '@/components/stories/Shopify/mobile/ShopifyProductMobileStory.vue';
import {mapGetters} from 'vuex';
import {getLargestCompareAtPrice} from '@/mixins/getLargestCompareAtPrice';

export default {
  name: 'ShopifyCollectionMobileStory',
  components: {ShopifyProductMobileStory},
  mixins: [getLargestCompareAtPrice],
  data() {
    return {
      selectedProduct: null,
    }
  },
  props: {
    scene: {
      type: Object,
      required: true
    },
    journey: {
      type: Object,
      required: true
    },
    collection: {
      type: Object | Array,
      required: true
    },
    isWindowMode: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      windowWidth: 'util/windowWidth',
    }),
    isCollection() {
      return 'products' in this.collection; // otherwise it's an array of products
    },
    collectionTitle() {
      return this.collection?.title || '';
    },
    collectionProducts() {
      if (this.isCollection) {
        return this.collection.products.nodes;
      }

      return this.collection;
    },
    collectionImageSize() {
      if (this.isWindowMode) {
        if (this.windowWidth > 400 && this.windowWidth <= 425) {
          if (this.windowWidth > 421) {
            return 180;
          }

          return 170;
        }

        if (this.windowWidth > 425) {
          return 120;
        }

        return Math.floor(0.5 * this.windowWidth - 30);
      }

      console.log('DDDD windowWidth: ', this.windowWidth);

      if (this.windowWidth > 500) {
        return Math.floor(0.333 * this.windowWidth - 30);
      }

      if (this.windowWidth > 400) {
        return Math.floor(0.5 * this.windowWidth - 35);
      }

      return Math.floor(0.5 * this.windowWidth - 30);
    },
  },
  created() {
    this.$store.dispatch('util/setWindowWidth', window.innerWidth);
  },
  methods: {
    selectProduct(product) {
      this.selectedProduct = product;
    },
    closeCollectionCard() {
      this.$emit('closeShopifyItemCard');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    getProductImageSource(product) {
      if (this.isCollection) {
        return product.featuredImage.src;
      }

      const imageNodes = product.images.nodes;

      if (imageNodes.length === 0) {
        return 'https://reeltok-assets.s3.us-west-1.amazonaws.com/placeholder.jpg';
      }

      return imageNodes[0].src;
    },
    getProductImageAlt(product) {
      if (this.isCollection) {
        return product.featuredImage.altText;
      }

      const imageNodes = product.images.nodes;

      if (imageNodes.length === 0) {
        return '';
      }

      return imageNodes[0].altText;
    },
  },
}
</script>
